<template>
  <b-row class="justify-content-md-center">
    <div class="card" style="width: 25rem; border-radius:15px;">
        
        <div class="card-body" style="padding:2rem;">
          <div class="logo mb-3" style="margin: auto;text-align: center;">
            <img src="/assets/images/logo.jpeg" alt="" style="max-width: 70px;">
          </div>
            <h5 class="card-title text-center">Silahkan Login</h5>
            <div class="card-text">
                <form @submit.prevent="onSubmit">
                    <div class="form-group">
                        <label for="email" class="label-form">Email</label>
                        <input type="text" ref="email" class="form-control" placeholder="Ketikkan email or username"  v-model="model.username" required>
                        <!-- <p class="text-danger">{{messageValidation.email}}</p> -->
                    </div>
                    <div class="form-group">
                        <label for="passwod" class="label-form">Password</label>
                        <input type="password" ref="password" class="form-control" placeholder="Ketikkan passsword" v-model="model.password" required>
                        <!-- <p class="text-danger">{{messageValidation.password}}</p> -->
                    </div>
                    <div class="form-group mb-0">
                        <button type="submit" class="btn btn-primary btn-login" style="width:100%;"><font-awesome-icon v-if="isLoading" icon="spinner" spin /> LOGIN</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </b-row>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      model: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      this.$store.dispatch('account/login', {
        username: this.model.username,
        password: this.model.password
      }).then(() => {
        // this.$store.dispatch('notification/welcome', this.$store.getters['account/accountData'].firstName)
        this.isLoading = false
        this.$router.push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/').then(() => {
          // location.reload()
        })
      }).catch(error => {
        this.isLoading = false
        this.$store.dispatch('notification/error', error)
        // this.$store.dispatch('notification/error', 'Kombinasi email & password anda salah')
      })
    }
  }
}
</script>